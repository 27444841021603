.product-text-color{
   color: #6B4E70;
 }

 .gift-icon{
  color: #6B4E70 !important;
  font-size: 25px !important;
 }

 .eye-icon{
  color: #6B4E70 !important;
  font-size: 25px !important;
 }


 .p-list-card{
  border: #f5c2fe 1px solid !important;
 }

 .text-p-list{
  color: #005b5a !important;
 }