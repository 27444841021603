.logo {
  height: 150px;
  width: 150px;
}

.navbar-nav.horizontal-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.navbar-nav.horizontal-menu li {
  list-style: none;
  margin: 0 15px;
}

.navbar-nav.horizontal-menu li a,
.navbar-nav.horizontal-menu li button {
  color: #6B4E70;
  text-decoration: none;
}

.navbar-nav.horizontal-menu li a:hover,
.navbar-nav.horizontal-menu li button:hover {
  color: #24A7A4;
}

.drawer-menu {
  list-style: none;
  padding: 0;
}

.drawer-menu li {
  margin-bottom: 15px;
}

.drawer-menu li a,
.drawer-menu li button {
  color: #6B4E70;
  text-decoration: none;
}

.drawer-menu li a:hover,
.drawer-menu li button:hover {
  color: #24A7A4;
}

.nav-button-color {
  background-color: #fff !important;
  border-color: #24A7A4 !important;
  color: #6B4E70 !important;
}

.nav-button-color:hover {
  background-color: #6B4E70 !important;
  border-color: #6B4E70 !important;
  color: #fff !important;
}

.nav-button-color-vendor {
  background-color: #6B4E70;
  border-color: #6B4E70;
  color: #fff !important;
}

.nav-button-color-vendor:hover {
  background-color: #fff !important;
  border-color: #24A7A4;
  color: #6B4E70 !important;
}

.mt-4 {
  margin-top: 1.5rem;
}


.gift-cart-icon{
  color: #6B4E70 !important;
  font-size: 30px !important;
 }



/* Sidebar.css */
/* Add these styles to your layout.css */

/* Optional: Adjustments for the fixed sidebar */
.fixed-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 200px; /* Adjust as needed */
  background-color: #fff; /* Adjust background color */
  z-index: 1000; /* Adjust z-index */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add box shadow */
  transition: transform 0.3s ease-in-out;
}

.fixed-sidebar.open {
  transform: translateX(0); /* Display the sidebar when open */
}

.fixed-sidebar ul {
  list-style: none;
  padding: 20px;
  margin: 0;
}

.fixed-sidebar li {
  margin-bottom: 15px;
}

/* Optional: Add styles for the active link */
.fixed-sidebar .nav-link.active {
  color: var(--themeRed);
}

.fixed-sidebar .nav-link:hover {
  background-color: transparent;
}

/* Optional: Adjustments for the toggle button */
.toggle-button {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1100; /* Place it above the sidebar */
  background-color: #fff; /* Adjust background color */
  border: none;
  padding: 10px;
  cursor: pointer;
}

/* Hide the toggle button on laptops and desktops */
@media (min-width: 992px) {
  .toggle-button {
    display: none;
  }
}

.nav-text-color {
  color: #6B4E70;
}

.newsletter-button {
  background-color: #24A7A4;
  border-color: #24A7A4;
  color: #fff;
}

.newsletter-button:hover {
  background-color: #24A7A4;
  border-color: #24A7A4;
  color: #fff;
}

.bg-newsletter {
  background-color: #6B4E70;
}

.footer-sm-text {
  font-size: xx-small !important;
}
