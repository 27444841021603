
.auth-button{
    background-color: #6B4E70;
    border-color: #6B4E70;
    color: #fff;
    border-radius: 10px;
    width: 180px;
  }

  .auth-button-container {
    display: flex;
    justify-content: center;
  }

  .auth-button:hover{
    background-color: transparent;
    border-color: #24A7A4;
    color: #6B4E70;
    width: 180px;
  }


  @media (max-width: 768px) {
    .col-lg-6.p-0 {
      background-image: none !important;
      height: 0% !important;
    }
  }


  .custom-spin{
    color: #24A7A4 !important;
  }
  
  .bg-body-auth
  {
   background: whitesmoke;
  
  }

  .auth-icon-avatar{
    border-color: #6B4E70;
    color: #6B4E70;
  
  }

  .auth-header-text{
    color: #6B4E70 !important;
  }