.gift-text-color{
   color: #6B4E70;
 }

 .gift-button-color{
   background-color: transparent;
   border-color: #24A7A4;
   color: #6B4E70;
 }
 
 .gift-button-color:hover{
   background-color: #6B4E70;
   border-color: #6B4E70;
   color: #fff;
 }


 .gift-button{
   background-color: #6B4E70;
   border-color: #6B4E70;
   color: #fff;
 }

 .gift-button:hover{
   background-color: #6B4E70;
   border-color: #6B4E70;
   color: #fff;
 }