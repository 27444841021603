/* .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.155); 
  }
   */
  .runti-fonts {
    font-size: 10px;
  }

  .form-switch input:disabled ~ .form-check-label {
    color: #adb5bd; /* or any other color to indicate disabled state */
  }

  .home-card-text-color{
    color: #6B4E70;
  }


  .bi-bell-fill{
    color: #24A7A4;
    border-color: #fff !important ;
  }

  .card-bg{
    background-color: #6B4E70;
  }


  .home-card{
    border: #f6c0ff 1px solid !important;
  }

  /* plan cards */

  .plan-card-text-color{
    color: #24A7A4;
  }
.plan-card-text-color-2{
  color:  #6B4E70;
}
  .plans-button{
    background-color: transparent;
    border-color: #24A7A4;
    color: #6B4E70;
  }
  
  .plan-content-text{
    color: #6B4E70;
  }

  .plans-button:hover{
    background-color: #6B4E70;
    border-color: #6B4E70;
    color: #fff;
  }




  .plans-button-sub{
    background-color: #24A7A4;
    border-color: #24A7A4;
    color: #fff;
  }
  
  .plans-button-sub:hover{
    background-color: #6B4E70;;
    border-color: #6B4E70;;
    color: #fff;
  }


.bg-plan-card-details{
  border-color: #24A7A4 !important;
}