
.accordion-button:not(.collapsed) {
    color: #fff;
    background-color: #6B4E70;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
    }


    .accordion-item {
        color: var(--bs-accordion-color);
        background-color: var(--bs-accordion-bg);
        border: #24A7A4 solid 0.5px;
        width: 40rem;
        margin: auto;
        }


  @media (max-width: 768px) {
    .accordion-item {
        color: var(--bs-accordion-color);
        background-color: var(--bs-accordion-bg);
        border: #24A7A4 solid 0.5px;
        width: 22rem;
        margin: auto;
    }
  }
