

.custom-spin {
    color: #24A7A4 !important;
  }


  .reminder-button-plan{
    background-color: #6B4E70;
    border-color: #6B4E70;
    color: #ffffff;
  }
  
  .reminder-button-plan:hover{
    background-color: transparent;
    border-color: #6B4E70;
    color: #6B4E70;
  }