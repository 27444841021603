.auth-button {
   background-color: #6b4e70 !important;
   border-color: #6b4e70 !important;
   color: #fff !important;
   border-radius: 10px !important;
   width: 180px !important;
 }
 
 .auth-button-container {
   display: flex;
   justify-content: center;
 }
 
 .auth-button:hover {
   background-color: #24a7a4 !important;
   border-color: #24a7a4 !important;
   color: #ffffff !important;
   width: 180px !important;
 }
 
 @media (max-width: 768px) {
   .col-lg-6.p-0 {
     background-image: none !important;
     height: 0% !important;
   }
 }
 
 .custom-spin {
   color: #717d7d !important;
 }
 
 .bg-body-auth {
   background: whitesmoke;
 }
 
 .auth-icon-avatar {
   color: #ffffff !important;
 }
 
 .auth-header-text {
   color: #ffff !important;
 }
 
 .form-container {
   display: flex;
   justify-content: center;
   align-items: center;
   min-height: 100vh;
 }
 
 .bg-container {
   background-image: url("../../../public/assets/images/runti-bd-card.jpeg");
   background-size: cover;
   background-position: center;
   background-color: #644c67;
   background-blend-mode: soft-light;
   height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center;
 }
 
 .bg-container-login {
  background-image: url("../../../public/assets/images/runti-bd-card2.jpeg");
  background-size: cover;
  background-position: center;
  background-color: #644c67;
  background-blend-mode: soft-light;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
