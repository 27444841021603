.vendorImage{
   border-radius: 5%;
}

.vendor-heroText{
   color: #6B4E70 !important;
}

.vendor-cta-button-color{
   background-color: transparent;
   border-color: #24A7A4;
   color: #6B4E70;
 }
 
 .vendor-cta-button-color:hover{
   background-color: #6B4E70;
   border-color: #6B4E70;
   color: #fff;
 }


 .vendor-cta-button{
   background-color: #6B4E70;
   border-color: #6B4E70;
   color: #fff;
 }

 .vendor-cta-button:hover{
   background-color: #6B4E70;
   border-color: #6B4E70;
   color: #fff;
 }