/* cart.css */
.cart-border-custom {
   border-radius: 8px;
   border: 1px solid #6B4E70;
 }
 
 .order-summary {
   margin-top: 16px;
 }
 
 .order-summary-text {
   font-size: 14px; /* Adjust font size as needed */
   margin-bottom: 8px;
 }
 
 .shadow-lg {
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
 }
 
 .border-custom {
   border: 1px solid #6B4E70;
 }
 

 .checkout-btn{
   background-color: #6B4E70 !important;
   border-color: #6B4E70 !important;
   color: #fff !important;
   border-radius: 10px !important;
   width: 380px !important;
 }


 .checkout-btn:hover{
   background-color: transparent !important;
   border-color: #24A7A4 !important;
   color: #6B4E70 !important;
   border-radius: 10px !important;
   width: 380px !important;
  
 }
